.detail-page {
    font-size: 1.5rem;
    margin-top: 15px;
    margin-bottom: 150px;
    .head-detail-page {
        align-items: center;
        align-self: center;
        .set-icon {
            margin-top: -10px;
        }
        .martop-icon {
            margin-top: -4px;
        }
        .text-topic {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            padding-left: 10px;
            padding-top: -10px;
        }
        .left-detail-page {
            font-family: Bebas Neue;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 36px;
            color: #000000;
        }
        .right-detail-page {
            text-align: right;
        }
        .text-point {
            padding-left: 10px;
            font-family: DB Heavent;
            font-style: normal;
            font-weight: bold;
            font-size: 27px;
            line-height: 32px;
            text-align: right;
            color: #000000;
        }
        .text-description {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 19px;
            text-align: right;
            color: #929292;
        }
        .inputdescription {
            border-width: 0px;
            border: none;
            text-align: right;
        }
    }

    .bg {
        background-color: white;
        border-radius: 10px;
    }
    .dashed {
        stroke-dasharray: 0;
        stroke: #e0e0e0;
        stroke-width: 20;
    }
    .detail-detail-page {
        .set-icon {
            margin-top: -10px;
        }
        .martop-icon-two {
            margin-top: -3px;
        }
        .text-detail-topic {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 26px;
            line-height: 31px;
            color: #000000;
            font-weight: 600;
        }
        .text-point {
            padding-left: 10px;
            font-family: DB Heavent;
            font-style: normal;
            font-weight: bold;
            font-size: 27px;
            line-height: 32px;
            text-align: right;
            color: #fdb101;
        }
        .text-date {
            padding-left: 10px;
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 26px;
            color: #000000;
        }
    }
    .bb {
        border-bottom: 1px solid #e0e0e0;
    }
    .select-size {
        select {
            min-height: 50px;
            border: 1px solid #E0E0E0;
            border-radius: 6px;
        }
    }
    .detail-condition-page {
        .text-topic {
            font-family: DB Heavent;
            font-style: normal;
            font-size: 20px;
            line-height: 24px;
            color: #000000;
            mix-blend-mode: normal;
            font-weight: bold;
        }
        .text-detail {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            color: #000000;
        }
        .mtb {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
    .footer-page-detail {
        background: #ffffff;
        box-shadow: 0px -0.5px 0px #dadada;
        border: 1px solid #e0e0e0;
        bottom: 0;
        left: 0;
        width: 100%;
        position: fixed;
        z-index: 99;
        padding-bottom: 2%;
        padding-top: 2%;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        > div > div .p-set {
            width: 100%;
            height: 50px;
            border-radius: 5px;
            color: #00833b;
            border-color: #00833b;
        }

        .box-quantity {
            margin: 1.5rem 0;
            padding: 0 1.5rem;
    
            .title {
                font-size: 20px;
                margin-bottom: 8px;
                color: gray;
            }
            .value-quantity {
                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        > div > div .a-set {
            width: 100%;
            height: 50px;
            border-radius: 5px;
            background: #00833b;
            border-radius: 5px;
            font-family: DB Heavent;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            text-align: center;
            color: #ffffff;
        }
    }
}

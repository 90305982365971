.address-page {
    margin-top: 20px;
}
.address-page-body {
    .text-title {
        font-family: DB Heavent;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 26px;
        color: #000000;
    }
    .text-form {
        font-family: DB Heavent;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #525252;
    }
    .input-address-form {
        height: 57px;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 5px;

        font-family: DB Heavent;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #000000;

        ::-webkit-input-placeholder {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            color: #949494;
        }

        :-ms-input-placeholder {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            color: #949494;
        }

        ::placeholder {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            color: #949494;
        }
    }
    .text-address {
        font-family: DB Heavent;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
    }
    .text-tal {
        font-family: DB Heavent;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .center-page {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .text-topic-no-address {
        font-family: DB Heavent;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #000000;
    }
    .text-detail-no-address {
        font-family: DB Heavent;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #949494;
    }
    .no-arrow {
        -moz-appearance: textfield;
    }
    .no-arrow::-webkit-inner-spin-button {
        display: none;
    }
    .no-arrow::-webkit-outer-spin-button,
    .no-arrow::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
.center {
    display: grid;
    place-items: center;
    .redeem {
        background: #f7f7f7;
        border-radius: 4px;
        height: 63px;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        .text-redeem {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #5a5a5a;
        }
    }

    .button-text {
        font-family: DB Heavent;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #ffffff;
        background-color: #00833b;
        width: 140px;
        border-radius: 20px;
    }
}

.footer-page-address {
    background: #ffffff;
    box-shadow: 0px -0.5px 0px #dadada;
    border: 1px solid #e0e0e0;
    bottom: 0;
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 99;
    padding-bottom: 2%;
    padding-top: 2%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    > div > div .p-set {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        color: #00833b;
        border-color: #00833b;
    }

    > div > div .a-set {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background: #00833b;
        border-radius: 5px;
        font-family: DB Heavent;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 25px;
        text-align: center;
        color: #ffffff;
    }
    > div > div .a-set-un {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background: #a6a6a6;
        border-radius: 5px;
        font-family: DB Heavent;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 25px;
        text-align: center;
        color: #ffffff;
    }
}

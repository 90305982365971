.group_slide {
    position: relative;

    .slide_item {
        cursor: pointer;
        border-radius: 7px;
        overflow: hidden;
        padding: 0 4px;
        width: 100%;
        height: auto;

        img {
            width: 100%;
            border-radius: 12px;
            border: 1px solid #dedede;
        }
    }

    .slide_item_height {

        // height: 116px !important;
        span {
            span {
                padding: 35.5% 0px 0px !important;
            }
        }
    }

    .slide_item .slide_item.h-130px {
        height: 130px;
        padding: 0 5px;
        margin: 0px 10px;
        // border: 1px solid #dedede;
    }

    .slick-dots {
        bottom: 7px;
    }

    .slick-dots li {
        width: 7px;
        height: 7px;
    }

    .slick-dots li button:before {
        content: '';
        background-color: #fff;
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }
}

// img {
//     filter: blur(0px) !important;
// }

.privilege-page {
    margin-top: 30px;
    margin-bottom: 30px;

    .head-privilege-page {
        .left-privilege-page {
            font-family: Bebas Neue;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 36px;
            color: #000000;
        }
    }

    .card-privilege-page {
        background: #ffffff;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        // height: 86px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px;

        .settocenter {
            margin-top: -12px;
            margin-left: 5px;
        }

        .center {
            align-items: center;
        }

        .text-topic {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 23px;
            line-height: 18px;
            color: #000000;
            font-weight: 600;
        }

        .text-detail {
            font-family: Bebas Neue;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 28px;
            text-align: center;
            color: #000000;
        }

        .text-discription {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 22px;
            color: #00833b;
            margin-left: 5px;
            margin-top: 10px;
            font-weight: 600;
        }

        .text-date {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 23px;
            line-height: 18px;
            letter-spacing: 0.1px;
            color: #000000;
            font-weight: 600;
        }

        .text-point {
            font-family: Bebas Neue;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 28px;
            text-align: center;
            color: #e30613;
        }

        .text-validate {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.001px;
            color: #595959;
        }

        .text-validate-buttom {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 22px;
            color: #d71820;
            padding-top: 10px;
            font-weight: 600;
        }
    }

    .cat-privilege-page {
        margin-bottom: 10px;

        .title-privilege-page {
            margin: 0;
            text-align: center;

            .testimonial-group>.row {
                display: block;
                overflow-x: auto;
                white-space: nowrap;
            }

            .testimonial-group>.row>.overflow-x {
                display: inline-block;
                text-align: center;
            }

            .overflow-x {
                padding-top: 18px;
                padding-right: 5px;
                padding-left: 5px;
                font-family: Bebas Neue;
                text-align: center;
                border: 0px;
            }

            font-family: Bebas Neue;
            background-color: #fff;

            .set-active {
                text-align: center;
                border-bottom: 2px solid #00833b;
                box-shadow: 0px 2px 0px #000000;
            }

            .text {
                font-family: DB Heavent;
                font-style: normal;
                font-weight: normal;
                font-size: 22px;
                line-height: 26px;
                color: #00833b;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin: 0px 16px;
            }

            .text-un {
                font-family: DB Heavent;
                font-style: normal;
                font-weight: normal;
                font-size: 22px;
                line-height: 26px;
                color: #ababab;
                flex: none;
                order: 1;
                flex-grow: 0;
            }

            // .overflow-x {
            // overflow: auto;
            // white-space: nowrap;
            // display: inline-block;
            // text-decoration: none;
            // }
        }
    }

    .topic-title-privilege-page {
        padding-top: 10px;

        .privilege-img {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .text-type {
            padding-top: 10px;
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 19px;
            color: #717171;
        }

        .text-discription {
            padding-top: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 23px;
            line-height: 24px;
            color: #000000;
        }

        .text-all {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 23px;
            color: #949393;
        }

        .text-reward {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 23px;
            color: #00833b;
        }

        .text-point {
            font-family: Arial;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            text-align: right;
            color: #ffffff;
        }

        .bg {
            background-color: white;
            border-radius: 10px;
            position: relative;
        }

        .top-left {
            position: absolute;
            top: 0;
            left: 0;
        }

        .bottom-right {
            position: absolute;
            bottom: 10px;
            right: 16px;
            background: #F00023 !important;
            border-radius: 6px;
            
            // padding-bottom: 3px;
        }
    }
}
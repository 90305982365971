$gray : #8E8E8E;
$green: #00833b;


.other-page {
    font-size: 1.5rem;
    .title-header {
        border-bottom: 1px solid #dee2e6;
    }
    .nav-tabs {
        margin-bottom: 0 !important;
        .nav-link {
            border: none !important;
            color: $gray !important;
            flex-grow: 1;
            text-align: center;

            &.active {
                color: $green ;
                border-bottom: 2px solid $green !important;
            }
        }
    }
    .noti-tab {
        
        .noti-list {
            border-bottom: 1px solid #E0E0E0;
            // background: #E9FFF3;

            .content {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
                overflow: hidden;
            }

            img {
                border-radius: 20px;
            }

            .date {
                color: #717171;
                font-size: 1rem;
            }
        }

        // &:not(:last-child) {
            
        // }
    }
    .favorite-tab {
        padding-top: 10px;
        .text-type {
            padding-top: 10px;
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 19px;
            color: #717171;
        }
        .text-discription {
            padding-top: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 23px;
            line-height: 24px;
            color: #000000;
        }
        .text-all {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 23px;
            color: #949393;
        }
        .text-reward {
            font-family: DB Heavent;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 23px;
            color: #00833b;
        }
        .text-point {
            font-family: Arial;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            text-align: right;
            color: #ffffff;
        }
        .bg {
            background-color: white;
            border-radius: 10px;
        }
        .top-left {
            position: absolute;
            top: 20px;
            left: 20px;
        }
        .bottom-right {
            position: absolute;
            bottom: 80px;
            right: 16px;
            background: #F00023;
            border-radius: 6px;
            // padding-bottom: 3px;
        }

    }
    .history-tab {
        .txt-point, .txt-status {
            color: $gray;
        }

        .list-redeem {
            border-bottom: 1px solid lighten($gray, 40);

            .list-name {
                font-weight: 600;
            }
        }
    }
    img {
        width: 100%;
    }

    .font-size-s {
        font-size: 1.2rem;
    }
}

  /* ส่วนรูปคูปอง */
  .coupon-image {
    width: 35%; /* กำหนดความกว้างภาพ */
    height: 100%;
    margin-right: 4px;
    overflow: hidden;
    border-radius: 4px;
  }
  
  .coupon-image img {
    width: 100%;
    height: 100%;
    // object-fit: cover; /* ให้รูปไม่เบี้ยว */
    object-fit: contain;
  }
  
  /* ส่วนข้อมูลคูปอง */
  .coupon-info {
    flex: 1; /* ให้กินพื้นที่ที่เหลือ */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
//   .coupon-title {
//     font-size: 16px;
//     font-weight: bold;
//     margin-bottom: 4px;
//   }
  
  .coupon-value {
    font-size: 14px;
    color: #00833b; /* สีเขียว หรือปรับตามต้องการ */
    margin-bottom: 4px;
  }
  
  .coupon-expire {
    font-size: 12px;
    color: #666;
  }
  

  
  .btn-redeem {
    background-color: #00833b;
    color: #fff;
    padding: 8px 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .label-used {
    background-color: #ccc;
    color: #fff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
  }
  
.swal2-actions {
    display: flex;
    flex-wrap: nowrap;
  }

  .swal-btn-confirm {
    background-color: #00833b !important; /* สีเขียว */
    color: #fff !important;
    border-radius: 24px !important; /* ปรับเป็นวงกลม */
    // width: 40px; /* กำหนดขนาดปุ่ม (กว้าง) */
    // height: 40px; /* กำหนดขนาดปุ่ม (สูง) */
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  .swal-btn-cancel {
    background-color: #ffffff !important; /* สีเทา */
    color: #00833b !important;
    border-radius: 24px !important; /* ปรับเป็นวงกลม */
    // width: 40px;
    // height: 40px;
    width: 100%;

    border: #00833b  1px solid !important;
    cursor: pointer;
  }
  


  .coupon-card {
    height: 150px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    margin-bottom: 8px;
    // padding: 12px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  /* ส่วนเนื้อหาคูปอง (ชื่อ, คะแนน, รายละเอียด, วันหมดอายุ) */
  .coupon-content {
    // flex: 1; /* ให้ขยายเต็มพื้นที่ที่เหลือ */
    width: 50%; /* ปรับขนาดตามต้องการ */
    height: 100%;   
     padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* ส่วนหัว: ชื่อคูปองและ Label (ถ้ามี) */
  .coupon-header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .coupon-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    // margin-right: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 80%;
  }
  
  .coupon-label-new {
    background-color: #ff9900; /* ตัวอย่างสีสำหรับ Label New */
    color: #fff;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 4px;
  }
  
  /* แสดงคะแนนหรือส่วนลด (ค่า / หน่วย) */
  .coupon-value {
    margin-bottom: 4px;
    font-size: 14px;
  }
  
  .coupon-value .value {
    font-size: 18px;
    font-weight: bold;
    color: #00833b; /* สีเขียว */
  }
  
  .coupon-value .unit {
    margin-left: 4px;
    font-size: 14px;
    color: #666;
  }
  
  /* รายละเอียดเพิ่มเติม */
  .coupon-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
  }
  
  /* วันหมดอายุ */
  .coupon-expire {
    font-size: 12px;
    color: #999;
  }
  
  /* ปุ่ม/สถานะคูปองทางขวา */
  .coupon-action {
    display: flex;
    width: 15%; /* ปรับขนาดตามต้องการ */
    align-items: center;
    // margin-left: 8px; /* เว้นระยะจาก coupon-content */
    height: 100%;
    margin-bottom:12px;
    margin-right:12px;
    flex-direction: column;
    // margin-left: auto; /* ดันไปชิดขวา */
    justify-content: flex-end;
  }
  
  .btn-coupon {
    background-color: #00833b;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 14px;
    width: 80px;
    // margin-bottom: 12px;
    margin-right: 12px;
    white-space: nowrap;
  }
  
  .coupon-used {
    background-color: #ccc;
    color: #fff;
    padding: 6px 12px;
    border-radius: 24px;
    font-size: 14px;
  }
  
$gray : #8E8E8E;
$green: #00833b;


.shipping-page {
    background: #F7F7F7;
    min-height: 100vh;
    font-size: 1.5rem;

    .title-header {
        background: white;
    }
    .txt-point, .txt-status {
        color: $gray;
    }

    .list-redeem {
        background: white;
        border-radius: 6px;

        .list-name {
            font-weight: 600;
        }
    }
    img {
        width: 100%;
    }

    .font-size-s {
        font-size: 1.2rem;
    }

    .timeline-wrapper {
        background: white;
        border-radius: 6px;

        .timeline-title {
            font-weight: 600;
        }

        .StepProgress {
            position: relative;
            padding-left: 35px;
            list-style: none;
    
            .StepProgress-item {
                position: relative;
                counter-increment: list;

                .Progress-status {
                    font-size: 1.8rem;
                    font-weight: 600;
                }

                .Progress-date-time {
                    font-size: 1rem;
                    color: $gray;
                }

                .Progress-address {
                    font-weight: 500;
                }

                &:not(:last-child):before {
                    display: inline-block;
                    content: "";
                    position: absolute;
                    top: 12px;
                    left: -31px;
                    width: 10px;
                    height: 100%;
                    border-left: 4px dotted #ccc;
                }

                &::after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 12px;
                    left: -35px;
                    width: 12px;
                    height: 12px;
                    border: 2px solid #ccc;
                    border-radius: 50%;
                    background-color: #fff;
                }

                &:not(:last-child) {
                    padding-bottom: 20px;
                }
                
                &.now {
                    .Progress-status {
                        color: $green;
                    }

                    &::after {
                        border-color: $green;
                    }
                    
                }
            }
        }
    }
    
}
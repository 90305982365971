body {
  margin: 0;
  font-family: DB Heavent !important;
}

.swal2-container {
  display: -webkit-box;
  display: flex;
  position: fixed;
  z-index: 300000 !important;
  font-family: DB Heavent;

}

.bgw {
  background-color: #fff;
}

.row.no-pad {
  margin-right: 0;
  margin-left: 0;
}

.row.no-pad>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.dashed {
  stroke-dasharray: 0;
  stroke: #e0e0e0;
  stroke-width: 20;
}

.text-r {
  text-align: right;
  float: right;
}

.marginTop-icon {
  margin-top: -4px;
}

.marginTop-14-icon {
  margin-top: -4px;
}

@font-face {
  font-family: Bebas Neue;
  src: local(Bebas Neue), url(./fonts/BebasNeue-Regular.ttf) format("truetype");
}

@font-face {
  font-family: DB Heavent;
  src: local(DB Heavent), url(./fonts/DB-Helvethaica.ttf) format("truetype");
}

.modal-dialog {
  outline: none !important;
}

.group-tab-code {
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  height: 100%;

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border: none;
    background: #00833b;
    line-height: 1;
    color: #fff !important;

    &.disabled {
      background: #f2f2f2;
      color: #9d9d9e;
    }
  }

  .nav-tabs {
    border-bottom: none;
    flex-wrap: nowrap;
    column-gap: 10px;
  }

  .tab-content>.tab-pane {
    text-align: center;
  }

  .tab-content {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 1;
    background: #fbfbfb;
    margin-top: 15px;
    padding: 0px 0 15px 0;
  }

  .nav-tabs .nav-link {
    border: none;
    background: #f2f2f2;
    border-radius: 6px;
    padding: 9px 15px;
    line-height: 1;
    color: #9d9d9d;
    width: 100%;
    font-size: 18px;
    font-weight: inherit;
    letter-spacing: 0.5px;
  }

  li {
    width: 100%;
  }
}

.countdown_box {
  display: flex;
  align-items: baseline;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  .countdown_box_item {
    display: flex;
    align-items: flex-end;

    &::after {
      content: ':';
      font-size: 26px;
      line-height: 1.25;
      margin: 0 2px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    p {
      text-align: center;
      font-size: 20px;
      margin-bottom: 0px !important;
    }

    span {
      color: #fff;
      display: block;
      font-size: 20px;
      display: grid;
      place-content: center;
      background: #00833b;
      width: 30px;
      height: 30px;
      border-radius: 6px;
    }
  }
}

.group-tab-privilege {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  height: 100%;

  /* align-items: center;
  flex-shrink: 0; */
  /* span {
    display: flex;

    padding-right: 8px;
    padding-left: 8px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

  } */

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #FFF !important;
    border-radius: 5px;
    /* border: 1px solid #009343; */
    background: #009343;

    /* Shadow */
    box-shadow: 0px 8px 16px 0px rgba(62, 19, 77, 0.07);

    &.disabled {
      border-radius: 10px;
      background: #DEDEDE;

      /* Shadow */
      box-shadow: 0px 8px 16px 0px rgba(62, 19, 77, 0.07);
    }
  }

  .nav-item {
    height: 42px;
    color: #000 !important;

    text-align: center;
    font-family: DB Heavent;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .nav-tabs {
    border-bottom: none;
    flex-wrap: nowrap;
    column-gap: 10px;
  }

  /* .tab-content>.tab-pane {
    text-align: center;
  }

  .tab-content {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 1;
    background: #fbfbfb;
    padding: 0px 0 15px 0;
  } */

  .nav-tabs .nav-link {
    border: none;
    background: #FFF;
    border-radius: 5px;
    padding: 9px 15px;
    line-height: 1;
    color: #000;
    width: 100%;
    font-size: 20px;
    font-weight: inherit;
    letter-spacing: 0.5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

  }

  li {
    width: 100%;
  }
}

.hide {
  display: none;
}

.group-code {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 28px;
  font-family: 'DB Heavent';

  .code-center {
    letter-spacing: 1px;

    img {
      width: 100%;
    }
  }
}

.group-privilege {
  display: inline-flex;
  height: 42px;
  padding: 6px 27px 7px 19px;
  align-items: center;
  flex-shrink: 0;
}

.modal-barcode {
  .modal-content {
    height: 82%;
  }

  .group-modal {
    padding: 0;
    padding-top: 0px;
    margin-top: 15px;
  }

  .modal-header {
    padding: 10px 20px;
    margin-bottom: 0px;
  }

  .des_promotion {
    text-align: left;
    width: 100%;
    padding: 0px 20px 0px 20px;
    line-height: 1.1;
    font-size: 22px;
    @include ellipsis(2);
  }

  .coppyCode {
    display: flex;
    align-items: center;
    flex-direction: column;

    >div {
      display: flex;
      font-size: 14px;
      padding: 4px 12px;
      background: #ececec;
      border-radius: 16px;
    }

    .ic-coppyCode {
      width: 16px;
      height: 18px;
      /* background-image: url(../images/icon/coppy.png); */
      background-repeat: no-repeat;
      margin-left: 10px;
    }
  }

  .modal-body {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
  }

  .group-tab-code {
    padding: 10px;
    padding-top: 0;
  }
}

.w-90 {
  width: 90% !important;
}

/* .height-100 {
  height: 100vh;
} */

.des_promotion {
  font-size: 22px;
  line-height: 25px;
  color: #000;
}

.modal_popup_container {
  position: fixed;
  z-index: 1;

  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  display: inline-block;
  margin: 0 auto;

  .hide {
    display: none !important;
  }

  .modal_popup {
    display: table;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    min-width: 327px;
    text-align: center;

    .img {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      max-width: 327px;
      max-height: 527px;
      display: table-cell;
      margin: 0 auto;
      vertical-align: middle;

      span {
        img {
          border-radius: 12px;
          object-fit: contain;
        }
      }
    }

    .popup_image {
      object-fit: contain;
      object-position: center;
      height: 100%;
    }

    .h3 {
      @include font-light;
      padding: 5px;
      color: #e5e5e5;
      font-size: 24px;
      cursor: pointer;
    }

    .close {
      margin: 10px 0px;
      font-size: 14px;
      border-radius: 50%;
      width: 17px;
      height: 17px;
      background-color: #fff;
      font-weight: bold;
    }

    .close_container {
      position: absolute;
      top: 0;
      right: 11px;
      font-size: 14px;
      padding: 0px 10px 20px 20px;
      font-weight: bold;
    }
  }
}

.group_input_container {
  display: flex;
  flex-direction: column;
}

.group_input_box {
  position: relative;
  width: 100%;
  margin-bottom: 0px;

  label {
    span {
      color: red
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }

  label {
    color: #848484;
    position: absolute;
    top: -8px;
    left: 15px;
    padding: 0 5px;
    background-color: #fff;
    @include ellipsis(1);
  }

  .lineborderInput {
    border: 1px solid #e0e0e0;
    height: 50px;
    border-radius: 5px;
    width: 100%;
    font-size: 18px;
    padding: 0 20px;
    box-shadow: unset;

    &.error {
      border-color: red;
    }
  }
}
.group_input_checkbox {
 
  .title_checkbox {
    font-size: 18px;
    color: #000;
    padding-left: 7px;
    text-decoration: underline;

  }

  .title {
    font-size: 18px;
    color: #000;
    padding-left: 7px;

    pre {
      font-family: inherit;
      word-break: break-word;
      word-spacing: 1px;
      letter-spacing: 1px;
      font-size: 18px;
    }

    br {
      padding: 5px 0;
    }

    span {
      white-space: pre-line;
    }

    strong {
      @include font-bold;
    }

  }

  .text_agreed {
    text-decoration: underline !important;
    color: #1d82d4 !important;
    margin-left: 20px !important;
  }

  input:checked~.checkmark {
    background-color: #009343;;
  }

  .checkmark {
    border-radius: 6px;
    width: 20px;
    height: 20px;

    &:after {
      left: 7px;
      top: 3px;
      width: 6px;
      font-size: 22px;
      height: 12px;
      border: solid white;
      border-width: 0px 2px 2px 0;
    }
  }

  .radio {
    border-radius: 50% !important;
    top: 0px !important;
  }

  .checkbox {
    top: 0px !important;
  }
}

.group_btn_form {
  .btn_layout {
    width: 100%;

    padding: 12px;
    border-radius: 5px;
    font-size: 18px;
    line-height: 1;
    @include font-regular;
    cursor: pointer;
  }

  .btn_blue {
    background-color: #009343;
    color: #fff;
    text-align: center;
  }

  .btn_gray {
    background-color: #727272;
    color: #fff;
    text-align: center;
    @include animation-delay(0.2);
    pointer-events: none;

  }
}

.group_input_upload {
  position: relative;
  width: 100%;
  margin-bottom: 0px;

  .title_upload {
    @include font-regular;
    /* padding-bottom: 12px; */
    font-size: 20px;
  }

  .group_zone {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    border: 1px dashed #e0e0e0;
    height: 150px;

    flex-wrap: wrap;

    label {
      font-size: 18px;
      @include font-regular;
      width: 100%;
      height: 100%;
      padding: 14% 0;
      span {
        display: block;
        width: 100%;
        padding-bottom: 5px;
      }
    }

    .upload {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
}


.group_checkbox {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 15px;
    width: 15px;
    /* border-radius: 50%; */
    background-color: #eee;
  }
}

.group_checkbox:hover input~.checkmark {
  background-color: #ccc;
}

/* Hide the browser's default checkbox */

/* When the checkbox is checked, add a blue background */
.group_checkbox input:checked~.checkmark {
  background-color: #009343;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.group_checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.group_checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.Modal_survey {
  .modal-header {
    padding: 10px 20px;
  }

  .modal-title {
    font-size: 24px;
    line-height: 1.2;
    @include font-bold;
    @include ellipsis(1);
  }

  .survey_OTP {
    padding: 20px 20px 0px !important;
    margin-bottom: 0px !important;
  }

  .survey_content {
    /* padding: 20px 20px; */
    margin-bottom: 10px;

    h1 {
      line-height: 0.8;
    }

    h2 {
      font-size: 30px;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      color: #000;
      line-height: 1.25;
    }

    .bracket_txt {
      color: rgb(39, 39, 39);
      margin-bottom: 10px;
    }
  }

  .box_layout {
    border: unset;
    padding: 0;
  }

  .group_input_checkbox .title_checkbox {
    font-size: 18px;
    color: #000;
    line-height: 1;
    padding-left: 7px;
    text-decoration: none;

    a {
      text-decoration: underline !important;
    }
  }

  .group_btn_form {
    .btn_cc {
      text-align: center;
      margin-top: 5px;
    }
  }
}